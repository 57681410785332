import React from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Paper, TextField, Typography } from '@mui/material';
import { FormControl, FormHelperText } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { signIn } from 'reducers/profile';
import { accountService } from 'services/account';

const LoginForm = () => {
  const dispatch = useDispatch();
  const { control, handleSubmit } = useForm({ mode: 'onChange' });
  const { mutate: login, isLoading } = useMutation(accountService.login, {
    onSuccess: ({ data: response }, { username }) => {
      dispatch(signIn({ ...response, username }));
    },
  });

  const handleClickLogin = () => {
    handleSubmit((values) => {
      login({ ...values });
    })();
  };

  return (
    <Paper className='flex flex-col py-10 px-16' style={{ width: 420 }}>
      <Typography variant='h2' color='primary' className='mb-2'>
        Login
      </Typography>
      <Typography className='text-neutral-100 mb-6'>Please login into your account</Typography>
      <Controller
        name='username'
        defaultValue=''
        control={control}
        rules={{ required: 'Username is required' }}
        render={({ field, fieldState: { invalid, error } }) => (
          <FormControl fullWidth className='mb-4'>
            <label>Username</label>
            <TextField {...field} />
            <FormHelperText hidden={!invalid}>{error?.message}</FormHelperText>
          </FormControl>
        )}
      />
      <Controller
        name='password'
        defaultValue=''
        control={control}
        rules={{ required: 'Password is required' }}
        render={({ field, fieldState: { invalid, error } }) => (
          <FormControl fullWidth className='mb-10'>
            <label>Password</label>
            <TextField type='password' {...field} />
            <FormHelperText hidden={!invalid}>{error?.message}</FormHelperText>
          </FormControl>
        )}
      />

      <LoadingButton fullWidth size='large' loading={isLoading} onClick={handleClickLogin}>
        LOGIN
      </LoadingButton>
    </Paper>
  );
};

export default LoginForm;
