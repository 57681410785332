import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
import { Button, FormControl, Grid, Paper, Tabs, Tab, TextField } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { gameService } from 'services/game';
import { marketService } from 'services/market';
import { privateRoute } from 'routes';
import { GameConfig } from 'views/Games';
import { FieldLogo, FieldFeatured, FieldOwner, FieldAvatarMedia } from 'views/Games/components';

const GameView = () => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, setValue } = useForm({ mode: 'onChange' });

  const { data } = useQuery(['marketService.fetchNetworks'], () => marketService.fetchNetworks());
  const { items: networkTabs = [] } = data;
  const [networkActive, setNetworkActive] = React.useState();

  React.useEffect(() => {
    setNetworkActive(networkTabs[0]?.id);
  }, [networkTabs]);

  const { data: game, isFetched: isFetchedGame } = useQuery(['gameService.getGameDetail', { id }], ({ queryKey }) =>
    gameService.getGameDetail({ ...queryKey[1] }).then((game) => {
      setValue('title', game.title ?? '');
      setValue('description', game.description ?? '');
      return game;
    }),
  );

  const [others, setOthers] = React.useState({});
  const handleChangeOthers = React.useCallback((next) => {
    setOthers((current) => ({ ...current, ...next }));
  }, []);

  const { mutate: updateGame, isLoading } = useMutation(gameService.updateGame, {
    onSuccess: () => {
      enqueueSnackbar('Update game successfully', { variant: 'success' });
    },
  });

  const handleClickSave = () => {
    handleSubmit((values) => {
      const { logoMedia, featuredMedia, avatarMedia, owners } = others;
      updateGame({
        id,
        ...values,
        logoMedia,
        avatarMedia,
        featuredMedia,
        owners,
        developerId: game.developer.id,
        status: game.status,
      });
    })();
  };

  return (
    <>
      <Paper className='p-6 mb-5'>
        <Grid container spacing={3}>
          <Grid item>
            <FieldLogo initialValue={game.logoMedia} onChange={handleChangeOthers} />
          </Grid>
          <Grid item md={12} lg={6}>
            <Controller
              name='title'
              defaultValue=''
              control={control}
              rules={{ required: ' ' }}
              render={({ field, fieldState: { invalid, error } }) => (
                <FormControl fullWidth className='mb-6'>
                  <label>Game</label>
                  <TextField {...field} error={invalid} />
                </FormControl>
              )}
            />

            <Controller
              name='description'
              defaultValue=''
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <FormControl fullWidth>
                  <label>Description</label>
                  <TextField {...field} multiline rows={5} error={invalid} helperText={error?.message} />
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
      </Paper>

      <Paper className='p-6 mb-5'>
        <FieldAvatarMedia initialValue={game.avatarMedia} onChange={handleChangeOthers} />
      </Paper>

      <Paper className='p-6 mb-5'>
        <FieldFeatured initialValue={game.featuredMedia} onChange={handleChangeOthers} />
      </Paper>
      <Paper className='p-6 mb-5'>
        <FieldOwner initialValue={game.owners} onChange={handleChangeOthers} />
      </Paper>

      <div className='flex justify-end gap-5 mb-10'>
        <Button
          component={Link}
          to={privateRoute.game.path}
          variant='outlined'
          color='inherit'
          startIcon={<ArrowBack />}
        >
          Back
        </Button>
        <LoadingButton className='w-40' loading={isLoading} onClick={handleClickSave}>
          Save
        </LoadingButton>
      </div>

      <Tabs value={networkActive} onChange={(_, nextTab) => setNetworkActive(nextTab)}>
        {networkActive && networkTabs.map((tab) => <Tab key={tab.id} value={tab.id} label={tab.name} />)}
      </Tabs>
      {networkTabs.map((network) => (
        <div key={network.id} className={network.id === networkActive ? '' : 'hidden'}>
          {isFetchedGame && (
            <GameConfig
              config={game.gameConfigs?.find((item) => item.network.id === network.id)}
              networkId={network.id}
            />
          )}
        </div>
      ))}
    </>
  );
};

export default GameView;
