import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Grid, IconButton, TextField, Typography } from '@mui/material';
import { DialogActions, DialogContent, DialogTitle, FormControl } from '@mui/material';
import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

const PopupBanner = ({ item, onSuccess, onClose, index }) => {
  const { control, handleSubmit, setValue } = useForm();
  const isCreate = !item;

  const handleClickCreate = () => {
    handleSubmit((values) => {
      onSuccess({ ...values, index });
      onClose();
    })();
  };

  React.useEffect(() => {
    if (item) {
      Object.entries(item).forEach(([key, value]) => {
        setValue(key, value ?? '');
      });
    }
  }, [setValue, item]);

  return (
    <>
      <DialogTitle>
        <Typography variant='h3' component='div'>
          {isCreate ? 'Create' : 'Update'} Review
        </Typography>
        <IconButton className='absolute top-3 right-6' onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item sm={6}>
            <Controller
              name='author'
              defaultValue=''
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <FormControl fullWidth className='mb-4'>
                  <label>Author</label>
                  <TextField {...field} error={invalid} helperText={error?.message} />
                </FormControl>
              )}
            />
          </Grid>
          <Grid item sm={6}>
            <Controller
              name='role'
              defaultValue=''
              control={control}
              rules={{ maxLength: 20 }}
              render={({ field, fieldState: { invalid, error } }) => (
                <FormControl fullWidth className='mb-4'>
                  <label>Role</label>
                  <TextField {...field} error={invalid} helperText={error?.message} />
                </FormControl>
              )}
            />
          </Grid>
        </Grid>

        <Controller
          name='comment'
          defaultValue=''
          control={control}
          render={({ field, fieldState: { invalid, error } }) => (
            <FormControl fullWidth className='mb-4'>
              <label>Comment</label>
              <TextField {...field} multiline error={invalid} helperText={error?.message} />
            </FormControl>
          )}
        />
      </DialogContent>

      <DialogActions className='justify-end px-6 py-4'>
        <LoadingButton onClick={handleClickCreate}>{isCreate ? 'Create' : 'Update'} Review</LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupBanner;
