import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CardMedia, Checkbox, Grid, IconButton, TextField, Typography } from '@mui/material';
import { DialogActions, DialogContent, DialogTitle, FormControl } from '@mui/material';
import { ArrowUpward, Close, ImageOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { fileService } from 'services/file';
import { getBase64 } from 'utils/common';

const PopupBanner = ({ item, onSuccess, onClose }) => {
  const { control, handleSubmit, clearErrors, setValue, watch } = useForm();
  const { type = '' } = watch();
  const [image, setImage] = React.useState();
  const [thumbnail, setThumbnail] = React.useState();
  const isCreate = !item;

  const handleClickCreate = () => {
    handleSubmit((values) => {
      onSuccess(values);
      onClose();
    })();
  };

  const handleChangeFile = (event) => {
    const [file] = event.target.files;
    if (!file) return;
    getBase64(file, setImage);
    fileService.uploadLink({ fileNames: [file.name], type: 'DEVELOPER_LOG' }).then(([url]) => {
      fileService.uploadFile(url, file);
      const { origin, pathname } = new URL(url);
      setValue('image', origin + pathname);
      setValue('type', file.type);
      clearErrors('image');
    });
  };

  const handleChangeThumbnail = (event) => {
    const [file] = event.target.files;
    if (!file) return;
    getBase64(file, setThumbnail);
    fileService.uploadLink({ fileNames: [file.name], type: 'DEVELOPER_LOG' }).then(([url]) => {
      fileService.uploadFile(url, file);
      const { origin, pathname } = new URL(url);
      setValue('thumbnail', origin + pathname);
      clearErrors('thumbnail');
    });
  };

  React.useEffect(() => {
    if (item) {
      Object.entries(item).forEach(([key, value]) => {
        setValue(key, value ?? '');
      });
      setImage(item.image);
      setThumbnail(item?.thumbnail);
    }
  }, [setValue, item]);

  return (
    <>
      <DialogTitle>
        <Typography variant='h3' component='div'>
          {isCreate ? 'Create' : 'Update'} Banner
        </Typography>
        <IconButton className='absolute top-3 right-6' onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Controller
          name='title'
          defaultValue=''
          control={control}
          render={({ field, fieldState: { invalid, error } }) => (
            <FormControl fullWidth className='mb-4'>
              <label>Banner</label>
              <TextField {...field} error={invalid} helperText={error?.message} />
            </FormControl>
          )}
        />

        <Controller
          name='image'
          defaultValue=''
          control={control}
          rules={{ required: ' ' }}
          render={({ field, fieldState: { invalid, error } }) => (
            <FormControl fullWidth className='mb-1'>
              <label>Upload image/video</label>
              <input hidden type='file' id='image' accept='image/*,video/*' onChange={handleChangeFile} />
              <label htmlFor='image' className='cursor-pointer'>
                <CardMedia
                  component={type.includes('video') ? 'video' : 'div'}
                  image={image}
                  className='flex items-center justify-center border-2 border-dashed bg-contain hover:border-gray-500 rounded h-52 w-full'
                  style={{
                    ...(invalid ? { borderColor: 'orangered' } : {}),
                    backgroundColor: 'rgba(250, 251, 252, 1)',
                  }}
                  {...{ controls: true, autoPlay: true, muted: true, loop: true }}
                >
                  {!image && <ImageOutlined className='w-14 h-14' sx={{ color: '#A5A9BA' }} />}
                </CardMedia>
              </label>
            </FormControl>
          )}
        />

        <Controller
          name='type'
          defaultValue=''
          control={control}
          render={({ field, fieldState: { invalid, error } }) => (
            <FormControl>
              <TextField {...field} className='hidden' />
            </FormControl>
          )}
        />

        <div className={type.includes('video') ? '' : 'hidden'}>
          <div className='flex justify-center mb-1'>
            <ArrowUpward fontSize='large' color='action' />
          </div>
          <Controller
            name='thumbnail'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <FormControl fullWidth className='mb-4'>
                <label>Upload Thumbnail</label>
                <input hidden type='file' id='thumbnail' accept='image/*' onChange={handleChangeThumbnail} />
                <label htmlFor='thumbnail' className='cursor-pointer'>
                  <CardMedia
                    component='div'
                    image={thumbnail}
                    className='flex items-center justify-center border-2 border-dashed bg-contain hover:border-gray-500 rounded h-52 w-full'
                    style={{ backgroundColor: 'rgba(250, 251, 252, 1)' }}
                  >
                    {!thumbnail && <ImageOutlined className='w-14 h-14' sx={{ color: '#A5A9BA' }} />}
                  </CardMedia>
                </label>
              </FormControl>
            )}
          />
        </div>

        <Grid container spacing={3}>
          <Grid item sm={6}>
            <Controller
              name='status'
              defaultValue=''
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <FormControl fullWidth className='mb-4'>
                  <label>Status</label>
                  <TextField {...field} error={invalid} helperText={error?.message} />
                </FormControl>
              )}
            />
          </Grid>
          <Grid item sm={6}>
            <Controller
              name='button'
              defaultValue=''
              control={control}
              rules={{ maxLength: 20 }}
              render={({ field, fieldState: { invalid, error } }) => (
                <FormControl fullWidth className='mb-4'>
                  <label>Button</label>
                  <TextField {...field} error={invalid} helperText={error?.message} />
                </FormControl>
              )}
            />
          </Grid>
        </Grid>

        <Controller
          name='description'
          defaultValue=''
          control={control}
          render={({ field, fieldState: { invalid, error } }) => (
            <FormControl fullWidth className='mb-4'>
              <label>Description</label>
              <TextField {...field} multiline error={invalid} helperText={error?.message} />
            </FormControl>
          )}
        />

        <Controller
          name='link'
          defaultValue=''
          control={control}
          render={({ field, fieldState: { invalid, error } }) => (
            <FormControl fullWidth className='mb-2'>
              <label>Link</label>
              <TextField {...field} error={invalid} helperText={error?.message} />
            </FormControl>
          )}
        />
        <Controller
          name='isOpenNew'
          defaultValue={false}
          control={control}
          render={({ field: { value, onChange } }) => (
            <FormControl className='flex flex-row items-center -ml-1.5'>
              <Checkbox
                className='p-1.5'
                checked={value}
                onChange={(event, checked) => onChange({ target: { value: checked } })}
              />
              <div>Open link in new tab</div>
            </FormControl>
          )}
        />
      </DialogContent>

      <DialogActions className='justify-end px-6 py-4'>
        <LoadingButton onClick={handleClickCreate}>{isCreate ? 'Create' : 'Update'} Banner</LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupBanner;
