import React from 'react';
import { CardMedia } from '@mui/material';
import { InfiniteSlider } from 'components';

const BottomBannerSlide = ({ items }) => {
  return (
    <InfiniteSlider autoplay className='card-container'>
      {items.map((item, index) => (
        <a className='block' key={index} href={item.link} target={item.isOpenNew ? '_blank' : '_self'}>
          <CardMedia image={item.image} className='w-full h-52 rounded' />
        </a>
      ))}
    </InfiniteSlider>
  );
};

export default BottomBannerSlide;
