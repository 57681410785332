import React from 'react';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { FormControl, IconButton, MenuItem, TextField, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { gameService } from 'services/game';

const PopupCreateGame = ({ onSuccess = () => {}, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm();

  const { data = {} } = useQuery(['gameService.fetchDevelopers'], () => gameService.fetchDevelopers({ limit: 100 }));
  const { items: developers = [] } = data;

  const { mutate: createGame, isLoading } = useMutation(gameService.createGame, {
    onSuccess: () => {
      enqueueSnackbar('Create game successfully', { variant: 'success' });
      onSuccess();
      onClose();
    },
  });

  const handleClickCreate = () => {
    handleSubmit((values) => {
      createGame({ ...values });
    })();
  };

  return (
    <div className='py-8 px-10' style={{ width: 480 }}>
      <Typography variant='h3' className='mb-6'>
        Create Game
      </Typography>
      <IconButton className='absolute top-6 right-6' onClick={onClose}>
        <Close />
      </IconButton>

      <Controller
        name='title'
        defaultValue=''
        control={control}
        rules={{ required: ' ' }}
        render={({ field, fieldState: { invalid, error } }) => (
          <FormControl fullWidth className='mb-6'>
            <label>Game</label>
            <TextField {...field} error={invalid} />
          </FormControl>
        )}
      />

      <Controller
        name='developerId'
        defaultValue=''
        control={control}
        rules={{ required: ' ' }}
        render={({ field, fieldState: { invalid, error } }) => (
          <FormControl fullWidth className='mb-6'>
            <label>Developer</label>
            <TextField
              select
              {...field}
              error={invalid}
              SelectProps={{
                MenuProps: { style: { height: 336 } },
              }}
            >
              {developers.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        )}
      />

      <div className='flex justify-end'>
        <LoadingButton className='w-48' loading={isLoading} onClick={handleClickCreate}>
          Create Game
        </LoadingButton>
      </div>
    </div>
  );
};

export default PopupCreateGame;
