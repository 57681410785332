import { configureStore } from '@reduxjs/toolkit';
import coreui from './coreui';
import profile from './profile';

export const store = configureStore({
  reducer: {
    coreui,
    profile,
  },
});
