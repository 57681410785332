import React from 'react';
import { Grid } from '@mui/material';
import { CardReview } from 'views/Homepage/previews';
import { InfiniteSlider } from 'components';

const ReviewSlides = ({ items }) => {
  return (
    <InfiniteSlider autoplay className='-mx-3'>
      {items.map((_, index) => (
        <Grid key={index} container spacing={3} className='flex -ml-3'>
          {items
            .concat(items.concat(items))
            .slice(index * 3, (index + 1) * 3)
            .filter((item, index, array) => array.findIndex((next) => next.index === item.index) === index)
            .map((item) => (
              <Grid item key={item.index} xs={12} sm={12} md={6} lg={4}>
                <CardReview item={item} />
              </Grid>
            ))}
        </Grid>
      ))}
    </InfiniteSlider>
  );
};

export default ReviewSlides;
