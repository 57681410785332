import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Divider, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import {
  SportsEsportsOutlined,
  LogoutOutlined,
  WidgetsOutlined,
  ArticleOutlined,
  HomeOutlined,
} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { signOut } from 'reducers/profile';
import { privateRoute } from 'routes';

const MenuItem = ({ name, icon, path, onClick }) => {
  const classes = useStyles();
  const location = useLocation();
  return (
    <ListItemButton
      classes={classes}
      component={path ? Link : undefined}
      to={path}
      selected={location.pathname.startsWith(path)}
      onClick={onClick}
    >
      <ListItemIcon className='min-w-min w-8'>{icon}</ListItemIcon>
      <ListItemText classes={{ primary: 'font-medium' }}>{name}</ListItemText>
    </ListItemButton>
  );
};

const Menu = () => {
  const dispatch = useDispatch();
  const { home, game, developer, saleSummary } = privateRoute;

  return (
    <List>
      <MenuItem {...home} icon={<HomeOutlined />} />
      <MenuItem {...game} icon={<SportsEsportsOutlined />} />
      <MenuItem {...developer} icon={<WidgetsOutlined />} />
      <MenuItem {...saleSummary} icon={<ArticleOutlined />} />
      <Divider className='bg-neutral-100 my-2' />
      <MenuItem name='Logout' icon={<LogoutOutlined />} onClick={() => dispatch(signOut())} />
    </List>
  );
};

const useStyles = makeStyles({
  root: {
    minHeight: 40,
    marginBottom: 4,
    color: '#979BAF',
    '& .MuiListItemIcon-root': {
      color: '#979BAF',
    },
    '&:hover': {
      backgroundColor: '#5D73DD !important',
      color: '#FFFFFF',
      '& .MuiListItemIcon-root': {
        color: '#FFFFFF',
      },
    },
  },
  selected: {
    backgroundColor: '#5D73DD !important',
    color: '#FFFFFF',
    '& .MuiListItemIcon-root': {
      color: '#FFFFFF',
    },
  },
});

export default Menu;
