import { Paper } from '@mui/material';
import { FormatQuote } from '@mui/icons-material';

const CardReview = ({ item = {} }) => {
  return (
    <Paper className='card-container p-6 h-72'>
      <FormatQuote color='primary' />
      <div className='text-lg max-line-4 break-words mb-10 h-28 whitespace-pre-wrap'>{item.comment}</div>
      <div className='text-lg font-bold text-primary-main mb-1'>{item.author}</div>
      <div className='text-sm font-bold text-light-50'>{item.role}</div>
    </Paper>
  );
};

export default CardReview;
