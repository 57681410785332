import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { usePopup } from 'hooks';
import { Button, Dialog, FormControl, IconButton, TextField, Typography } from '@mui/material';
import { Table, TableHead, TableBody, TableCell, TableRow } from '@mui/material';
import { Close } from '@mui/icons-material';
import { isValidAddress } from 'utils/validator';

const FieldOwner = ({ initialValue, onChange }) => {
  const { control, handleSubmit, setValue, clearErrors } = useForm({ mode: 'onChange' });

  const [isOpenPopup, onOpenPopup, onClosePopup] = usePopup();
  const [chosenIndex, setChosenIndex] = React.useState();
  const [owners, setOwners] = React.useState([]);

  const handleClickSave = () => {
    handleSubmit((values) => {
      setOwners((items) => {
        items[chosenIndex] = values;
        return items.slice();
      });
      onClosePopup();
    })();
  };

  React.useEffect(() => {
    if (isOpenPopup) {
      setValue('walletName', owners[chosenIndex]?.walletName ?? '');
      setValue('address', owners[chosenIndex]?.address ?? '');
    } else {
      clearErrors();
    }
  }, [setValue, clearErrors, isOpenPopup, owners, chosenIndex]);

  React.useEffect(() => {
    onChange({ owners });
  }, [onChange, owners]);

  React.useEffect(() => {
    setOwners(initialValue?.slice() ?? []);
  }, [initialValue]);

  return (
    <div>
      <Typography variant='h5' className='mb-3'>
        Game Owners
        <Button
          size='small'
          className='w-20 ml-6'
          onClick={() => {
            onOpenPopup();
            setChosenIndex(owners.length);
          }}
        >
          Add
        </Button>
      </Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Wallet Name</TableCell>
            <TableCell>Address</TableCell>
            <TableCell className='text-right'>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {owners.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{item.walletName}</TableCell>
              <TableCell>{item.address}</TableCell>
              <TableCell className='flex justify-end gap-3'>
                <Button
                  size='small'
                  color='error'
                  onClick={() => {
                    setOwners((items) => {
                      items.splice(index, 1);
                      return items.slice();
                    });
                  }}
                >
                  Remove
                </Button>
                <Button
                  size='small'
                  color='info'
                  onClick={() => {
                    onOpenPopup();
                    setChosenIndex(index);
                  }}
                >
                  Edit
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {owners.length === 0 && <div className='text-red-600 mt-3'>Please add at least one owner</div>}

      <Dialog open={isOpenPopup}>
        <div className='py-8 px-10' style={{ width: 480 }}>
          <Typography variant='h3' className='mb-6'>
            Owner
          </Typography>
          <IconButton className='absolute top-6 right-6' onClick={onClosePopup}>
            <Close />
          </IconButton>

          <Controller
            name='walletName'
            defaultValue=''
            control={control}
            rules={{ required: 'Wallet Name is required' }}
            render={({ field, fieldState: { invalid, error } }) => (
              <FormControl fullWidth className='mb-6'>
                <label>Wallet Name</label>
                <TextField {...field} error={invalid} helperText={error?.message} />
              </FormControl>
            )}
          />
          <Controller
            name='address'
            defaultValue=''
            control={control}
            rules={{
              validate: { pattern: (value) => isValidAddress(value) || 'Address is not valid' },
            }}
            render={({ field, fieldState: { invalid, error } }) => (
              <FormControl fullWidth className='mb-6'>
                <label>Address</label>
                <TextField {...field} error={invalid} helperText={error?.message} />
              </FormControl>
            )}
          />

          <div className='flex justify-end'>
            <Button className='w-32' onClick={handleClickSave}>
              Save
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default FieldOwner;
