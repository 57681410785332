import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { Dialog, Paper } from '@mui/material';
import { marketService } from 'services/market';
import { FieldBanner, FieldItems, FieldGames, FieldAds, FieldNews, FieldReview } from 'views/Homepage/components';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { usePopup } from 'hooks';
import { PopupPreview } from './popups';

const Homepage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isOpenPopup, onOpenPopup, onClosePopup] = usePopup();

  const { data } = useQuery(['marketService.fetchHomepage'], () => marketService.fetchHomepage());
  const { landingPage = {} } = data;
  const [banners, setBanners] = React.useState([]);
  const [items, setItems] = React.useState([]);
  const [games, setGames] = React.useState([]);
  const [ads, setAds] = React.useState([]);
  const [news, setNews] = React.useState([]);
  const [reviews, setReviews] = React.useState([]);
  const isValid =
    banners.length >= 4 &&
    items.length >= 4 &&
    games.length >= 3 &&
    games.length <= 6 &&
    ads.length >= 1 &&
    news.length >= 4 &&
    reviews.length >= 3;

  const { mutate: updateHomepage, isLoading } = useMutation(marketService.updateHomepage, {
    onSuccess: () => {
      enqueueSnackbar('Update successfully', { variant: 'success' });
      onClosePopup();
    },
  });

  const handleClickSave = () => {
    if (isValid) {
      updateHomepage({
        landingPage: {
          banners: banners.map((item, index) => ({ ...item, index })),
          items: items.map(({ id }, index) => ({ id, index })),
          games: games.map(({ id }, index) => ({ id, index })),
          ads: ads.map((item, index) => ({ ...item, index })),
          news: news.map((item, index) => ({ ...item, index })),
          reviews: reviews.map((item, index) => ({ ...item, index })),
        },
      });
    } else {
      enqueueSnackbar('Please check form requirement', { variant: 'error' });
    }
  };

  return (
    <>
      <Paper className='p-6 mb-5'>
        <FieldBanner initialValues={landingPage.banners} onChange={setBanners} />
      </Paper>
      <Paper className='p-6 mb-5'>
        <FieldItems initialValues={landingPage.items} onChange={setItems} />
      </Paper>
      <Paper className='p-6 mb-5'>
        <FieldGames initialValues={landingPage.games} onChange={setGames} />
      </Paper>
      <Paper className='p-6 mb-5'>
        <FieldAds initialValues={landingPage.ads} onChange={setAds} />
      </Paper>
      <Paper className='p-6 mb-5'>
        <FieldNews initialValues={landingPage.news} onChange={setNews} />
      </Paper>
      <Paper className='p-6 mb-5'>
        <FieldReview initialValues={landingPage.reviews} onChange={setReviews} />
      </Paper>
      <div className='flex justify-end gap-3'>
        <LoadingButton variant='outlined' color='info' className='w-32' onClick={onOpenPopup} loading={isLoading}>
          Preview
        </LoadingButton>
        <LoadingButton className='w-32' onClick={handleClickSave} loading={isLoading}>
          Save
        </LoadingButton>
      </div>
      <Dialog fullWidth maxWidth='xl' open={isOpenPopup}>
        <PopupPreview
          data={{ banners, items, games, ads, news, reviews }}
          onClose={onClosePopup}
          onSuccess={handleClickSave}
        />
      </Dialog>
    </>
  );
};

export default Homepage;
