import React from 'react';
import { Tooltip } from '@mui/material';
import { shorten } from 'utils/common';

var timer;

const Copiable = ({ address, before, after }) => {
  const [isCopy, setIsCopy] = React.useState(false);
  const isAddress = address.startsWith('0x');

  const handleClickCopy = () => {
    clearTimeout(timer);
    try {
      navigator.clipboard.writeText(address);
    } catch {
    } finally {
      setIsCopy(true);
      timer = setTimeout(() => {
        setIsCopy(false);
      }, 2000);
    }
  };

  return isAddress ? (
    <Tooltip title={isCopy ? 'Copied!' : 'Copy'} onOpen={() => setIsCopy(false)}>
      <span onClick={handleClickCopy}>{shorten(address, before, after)}</span>
    </Tooltip>
  ) : (
    <span>{address}</span>
  );
};

export default Copiable;
