import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Avatar, Checkbox, IconButton, TextField, Typography } from '@mui/material';
import { DialogActions, DialogContent, DialogTitle, FormControl } from '@mui/material';
import { Close, ImageOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { fileService } from 'services/file';
import { getBase64 } from 'utils/common';

const PopupBanner = ({ item, onSuccess, onClose }) => {
  const { control, handleSubmit, clearErrors, setValue } = useForm();
  const [image, setImage] = React.useState();
  const isCreate = !item;

  const handleClickCreate = () => {
    handleSubmit((values) => {
      onSuccess(values);
      onClose();
    })();
  };

  const handleChangeFile = (event) => {
    const [file] = event.target.files;
    if (!file) return;
    getBase64(file, setImage);
    fileService.uploadLink({ fileNames: [file.name], type: 'DEVELOPER_LOG' }).then(([url]) => {
      fileService.uploadFile(url, file);
      const { origin, pathname } = new URL(url);
      setValue('image', origin + pathname);
      clearErrors('image');
    });
  };

  React.useEffect(() => {
    if (item) {
      Object.entries(item).forEach(([key, value]) => {
        setValue(key, value ?? '');
      });
      setImage(item.image);
    }
  }, [setValue, item]);

  return (
    <>
      <DialogTitle>
        <Typography variant='h3' component='div'>
          {isCreate ? 'Create' : 'Update'} Ads
        </Typography>
        <IconButton className='absolute top-3 right-6' onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Controller
          name='image'
          defaultValue=''
          control={control}
          rules={{ required: ' ' }}
          render={({ field, fieldState: { invalid, error } }) => (
            <FormControl fullWidth className='mb-4'>
              <input hidden type='file' id='image' accept='image/*' onChange={handleChangeFile} />
              <label htmlFor='image' className='cursor-pointer'>
                <Avatar
                  src={image}
                  variant='rounded'
                  className='border-2 border-dashed hover:border-gray-500 h-52 w-full'
                  style={invalid ? { borderColor: 'orangered' } : {}}
                >
                  <ImageOutlined className='w-14 h-14' />
                </Avatar>
              </label>
            </FormControl>
          )}
        />

        <Controller
          name='link'
          defaultValue=''
          control={control}
          render={({ field, fieldState: { invalid, error } }) => (
            <FormControl fullWidth className='mb-2'>
              <label>Link</label>
              <TextField {...field} error={invalid} helperText={error?.message} />
            </FormControl>
          )}
        />
        <Controller
          name='isOpenNew'
          defaultValue={false}
          control={control}
          render={({ field: { value, onChange } }) => (
            <FormControl className='flex flex-row items-center -ml-1.5'>
              <Checkbox
                className='p-1.5'
                checked={value}
                onChange={(event, checked) => onChange({ target: { value: checked } })}
              />
              <div>Open link in new tab</div>
            </FormControl>
          )}
        />
      </DialogContent>

      <DialogActions className='justify-end px-6 py-4'>
        <LoadingButton onClick={handleClickCreate}>{isCreate ? 'Create' : 'Update'} Ads</LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupBanner;
