import { client } from './axios';

const fetchGames = (params) => client.get(`/games`, { params });
const createGame = (body) => client.post(`/games`, body);

const getItemDetail = ({ id }) => client.get(`/nfts/${id}`);
const getGameDetail = ({ id }) => client.get(`/games/${id}`);
const updateGame = ({ id, ...body }) => client.put(`/games/${id}`, body);
const updateGameStatus = ({ id, ...body }) => client.put(`/games/${id}/status`, body);
const updateGameConfig = ({ id, networkId, ...body }) => client.post(`/games/${id}/config/${networkId}`, body);

const updateGameFilter = ({ id, ...body }) => client.patch(`/games/${id}/filter`, body);
const updateGameDisplay = ({ id, ...body }) => client.patch(`/games/${id}/display`, body);

const fetchDevelopers = (params) => client.get(`/developers`, { params });
const createDeveloper = (body) => client.post(`/developers`, body);

const getDeveloperDetail = (id) => client.get(`/developers/${id}`);
const updateDeveloper = ({ id, ...body }) => client.put(`/developers/${id}`, body);
const removeDeveloper = (id) => client.delete(`/developers/${id}`);

export const gameService = {
  fetchGames,
  createGame,

  getItemDetail,
  getGameDetail,
  updateGame,
  updateGameStatus,
  updateGameConfig,

  updateGameFilter,
  updateGameDisplay,

  fetchDevelopers,
  createDeveloper,

  getDeveloperDetail,
  updateDeveloper,
  removeDeveloper,
};
