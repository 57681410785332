import { useAlert } from 'hooks';
import { createTheme, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/lab';
import { default as DateAdapter } from '@mui/lab/AdapterLuxon';

export const appTheme = createTheme({
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body1: 'div',
          body2: 'div',
          inherit: 'div',
        },
      },
      styleOverrides: {
        h1: { fontSize: 36, fontWeight: 700 },
        h2: { fontSize: 28, fontWeight: 700 },
        h3: { fontSize: 24, fontWeight: 700 },
        h4: { fontSize: 20, fontWeight: 700 },
        h5: { fontSize: 16, fontWeight: 700 },
        h6: { fontSize: 14, fontWeight: 700 },
        subtitle1: { fontSize: 16, fontWeight: 500 },
        subtitle2: { fontSize: 14, fontWeight: 500 },
        body1: { fontSize: 14 },
        body2: { fontSize: 12 },
        caption: { fontSize: 11 },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        color: 'primary',
        size: 'medium',
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 500,
        },
        containedPrimary: {
          background: 'linear-gradient(31.65deg, #DEB856 6.17%, #FDE29B 93.12%)',
          '&:hover': {
            background: 'linear-gradient(59.24deg, #EECC75 18.13%, #F9EDCB 95.47%)',
          },
        },
        sizeLarge: { minHeight: 48, minWidth: 48 },
        sizeMedium: { minHeight: 40, minWidth: 40 },
        sizeSmall: { minHeight: 32, minWidth: 32 },
        outlinedInherit: { borderColor: '#0006' },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          '&.Mui-selected': {
            backgroundColor: '#FFF',
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root.Mui-focused fieldset': {
            borderWidth: 1,
            boxShadow: '0px 0px 4px 0px #CFB675',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: 'var(--neutral-light-20)',
          },
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        error: true,
      },
    },
    MuiLink: {
      defaultProps: {
        target: '_blank',
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiAvatar: {
      defaultProps: {
        sx: { width: 24, height: 24 },
      },
    },
    MuiPagination: {
      defaultProps: {
        variant: 'outlined',
        shape: 'rounded',
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: 'var(--neutral-light-30) !important',
          border: '1px solid #fff',
        },
        body: {
          backgroundColor: 'var(--neutral-light-10)',
          border: '1px solid #fff',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'var(--neutral-light-20) !important',
            '> .MuiTableCell-body': {
              backgroundColor: 'transparent',
            },
          },
        },
      },
    },
  },
  typography: {
    fontFamily: `Nunito, 'Segoe UI Variable'`,
  },
  palette: {
    primary: {
      main: '#CFB675',
    },
  },
});

const Theme = ({ children }) => {
  useAlert();
  return (
    <ThemeProvider theme={appTheme}>
      <LocalizationProvider dateAdapter={DateAdapter}>{children}</LocalizationProvider>
    </ThemeProvider>
  );
};

export default Theme;
