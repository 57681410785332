import React from 'react';
import { useQuery } from 'react-query';
import { Checkbox, FormControl, FormControlLabel, Typography } from '@mui/material';
import { marketService } from 'services/market';

const FieldCurrency = ({ initialValue, networkId, onChange }) => {
  const { data } = useQuery(['marketService.fetchPaymentTokens'], () => marketService.fetchPaymentTokens(), {
    staleTime: Infinity,
    refetchOnMount: false,
  });
  const { items: paymentTokens = [] } = data;

  const [values, setValues] = React.useState([]);

  React.useEffect(() => {
    onChange({ paymentTokenIds: values });
  }, [onChange, values]);

  React.useEffect(() => {
    setValues(initialValue?.map((item) => item.id) ?? []);
  }, [initialValue]);

  return (
    <div>
      <Typography variant='h5' className='mb-1'>
        Currency
      </Typography>
      <FormControl className='flex flex-row px-1'>
        {paymentTokens
          .filter((item) => item.networkId === networkId)
          .map((item) => (
            <FormControlLabel
              key={item.id}
              label={item.symbol}
              className='mr-6'
              control={<Checkbox className='p-1.5' checked={values.includes(item.id)} />}
              onChange={(event, checked) => {
                if (checked) {
                  setValues((array) => array.concat(item.id));
                } else {
                  setValues((array) => array.filter((next) => next !== item.id));
                }
              }}
            />
          ))}
      </FormControl>
      {values.length === 0 && <div className='text-red-600 mt-1'>Please choose at least one payment token</div>}
    </div>
  );
};

export default FieldCurrency;
