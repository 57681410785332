import React from 'react';
import { Avatar, Button, CardMedia, CircularProgress, Grid, Typography } from '@mui/material';
import { AddPhotoAlternateOutlined, ArrowUpward, Close } from '@mui/icons-material';
import { fileService } from 'services/file';

const FieldFeatured = ({ initialValue, onChange }) => {
  const [featuredImages, setFeaturedImages] = React.useState([]);
  const invalid = featuredImages.length === 0;

  const handleChangeFiles = (event) => {
    const files = Object.values(event.target.files);
    if (files.length === 0) return;
    fileService.uploadLink({ fileNames: files.map((file) => file.name), type: 'GAME_IMAGE' }).then((urls) => {
      urls.forEach((url, index) => {
        let pilot;
        setFeaturedImages((items) => {
          pilot = items.length;
          return items.concat(undefined);
        });
        fileService.uploadFile(url, files[index]).then(() => {
          const { origin, pathname } = new URL(url);
          setFeaturedImages((items) => {
            items[pilot] = { url: origin + pathname, type: files[index].type };
            return items.slice();
          });
        });
      });
    });
  };

  const handleChangeThumbnail = (event, index) => {
    const [file] = event.target.files;
    if (!file) return;
    fileService.uploadLink({ fileNames: [file.name], type: 'GAME_IMAGE' }).then(([url]) => {
      fileService.uploadFile(url, file).then(() => {
        const { origin, pathname } = new URL(url);
        setFeaturedImages((items) => {
          items[index] = { ...items[index], thumbnail: origin + pathname };
          return items.slice();
        });
      });
    });
  };

  React.useEffect(() => {
    onChange({
      featuredMedia: featuredImages.map((item) => ({ url: item?.url, thumbnailUrl: item?.thumbnail })),
    });
  }, [onChange, featuredImages]);

  React.useEffect(() => {
    setFeaturedImages(
      initialValue?.map((item) => ({ url: item.url, type: item.type, thumbnail: item.thumbnail })) ?? [],
    );
  }, [initialValue]);

  return (
    <div>
      <Typography variant='h5' className='mb-1'>
        Featured Images/Videos
      </Typography>
      <Grid container>
        <Grid item>
          <input multiple hidden type='file' id='images' accept='image/*,video/*' onChange={handleChangeFiles} />
          <div className={'mb-4' + (invalid ? ' text-red-600' : '')}>You must upload at least 4 images or videos.</div>
          <div className='flex'>
            <label htmlFor='images' className='cursor-pointer'>
              <Avatar
                variant='rounded'
                className={'border-2 border-dashed hover:border-gray-500 w-40 h-32'}
                style={invalid ? { borderColor: 'orangered' } : {}}
              >
                <AddPhotoAlternateOutlined className='w-20 h-20' />
              </Avatar>
            </label>
          </div>
        </Grid>
        <Grid item xs>
          <div className='flex flex-wrap mt-9'>
            {featuredImages.map((image, index) => (
              <div key={Math.random()} className='relative mr-6 mb-6'>
                <CardMedia
                  component={image?.type.includes('video') ? 'video' : 'div'}
                  image={image?.url}
                  className='flex items-center justify-center rounded w-40 h-32'
                  {...{ controls: true, autoPlay: true, muted: true, loop: true }}
                >
                  {!image && <CircularProgress color='inherit' />}
                </CardMedia>
                <Button
                  size='small'
                  color='inherit'
                  className='absolute -top-3 -right-3 rounded-full min-w-0 min-h-0 w-6 h-6'
                  onClick={() => {
                    setFeaturedImages((items) => {
                      items.splice(index, 1);
                      return items.slice();
                    });
                  }}
                >
                  <Close fontSize='small' />
                </Button>

                {image?.type.includes('video') && (
                  <>
                    <div className='flex justify-center my-2'>
                      <ArrowUpward fontSize='small' color='action' />
                    </div>
                    <div>
                      <input
                        hidden
                        type='file'
                        id={`thumbnail-${index}`}
                        accept='image/*'
                        onChange={(event) => handleChangeThumbnail(event, index)}
                      />
                      <div className='flex'>
                        <label htmlFor={`thumbnail-${index}`} className='cursor-pointer'>
                          <Avatar
                            src={image?.thumbnail}
                            variant='rounded'
                            className={'border-2 border-dashed hover:border-gray-500 w-40 h-32'}
                            style={invalid ? { borderColor: 'orangered' } : {}}
                          >
                            <AddPhotoAlternateOutlined className='w-20 h-20' />
                          </Avatar>
                        </label>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default FieldFeatured;
