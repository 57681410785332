import { AccessTime } from '@mui/icons-material';
import { CardMedia, Grid, Paper } from '@mui/material';
import { getTimeAgo } from 'utils/common';

const CardNews = ({ item = {} }) => {
  return (
    <Paper className='card-container p-4' title={item.title}>
      <Grid container spacing={3}>
        <Grid item md={6} lg={6}>
          <CardMedia image={item.image} className='w-full rounded h-56' />
        </Grid>
        <Grid item md={6} lg={6}>
          <div>
            <div className='text-lg font-semibold mb-2 max-line-1'>{item.title}</div>
            <div className='text-sm text-light-90 max-line-6 whitespace-pre-wrap mb-6' style={{ height: 120 }}>
              {item.description}
            </div>
            <div className='text-sm text-light-90'>
              <AccessTime fontSize='small' className='mr-1' />
              <span>{getTimeAgo(item.createdAt ?? '1970-01-15T03:32:12.000Z')}</span>
            </div>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CardNews;
