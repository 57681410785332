import React from 'react';
import { Grid } from '@mui/material';
import { CardItem } from 'views/Homepage/previews';
import { InfiniteSlider } from 'components';

const CardSlides = ({ items }) => {
  return (
    <InfiniteSlider autoplay className='-mx-3'>
      {items.map((_, index) => (
        <Grid key={index} container spacing={3} className='flex -ml-3'>
          {items
            .concat(items.concat(items.concat(items)))
            .slice(index * 4, (index + 1) * 4)
            .filter((item, index, array) => array.findIndex((next) => next.id === item.id) === index)
            .map((item) => (
              <Grid item key={item.id} xs={12} sm={6} md={4} lg={3}>
                <a href='/'>
                  <CardItem item={item} display='list' />
                </a>
              </Grid>
            ))}
        </Grid>
      ))}
    </InfiniteSlider>
  );
};

export default CardSlides;
