import React from 'react';
import { Avatar, FormControl } from '@mui/material';
import { ImageOutlined } from '@mui/icons-material';
import { fileService } from 'services/file';

const FieldLogo = ({ initialValue, onChange }) => {
  const [logo, setLogo] = React.useState();

  const handleChangeFile = (event) => {
    const [file] = event.target.files;
    if (!file) return;
    fileService.uploadLink({ fileNames: [file.name], type: 'GAME_IMAGE' }).then(([url]) => {
      fileService.uploadFile(url, file).then(() => {
        const { origin, pathname } = new URL(url);
        setLogo(origin + pathname);
      });
    });
  };

  React.useEffect(() => {
    onChange({ logoMedia: { url: logo } });
  }, [onChange, logo]);

  React.useEffect(() => {
    setLogo(initialValue?.url);
  }, [initialValue]);

  return (
    <div>
      <input hidden type='file' id='logo' accept='image/*' onChange={handleChangeFile} />
      <div className='flex mb-3'>
        <label htmlFor='logo' className='rounded-full cursor-pointer'>
          <Avatar
            src={logo}
            className={'border-2 border-dashed hover:border-gray-500 h-32 w-32'}
            style={logo ? {} : { borderColor: 'orangered' }}
          >
            <ImageOutlined className='w-20 h-20' />
          </Avatar>
        </label>
      </div>
      <FormControl fullWidth>
        <label>Logo</label>
        <div>
          Recommend an image of at least 800 x 800px.
          <br />
          Maximum size 5MB.
        </div>
      </FormControl>
    </div>
  );
};

export default FieldLogo;
