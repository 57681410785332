import React from 'react';
import { Button, Container, Grid, Typography } from '@mui/material';
import { DialogContent, DialogTitle } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { GameSlides, CardSlides, CardGame, BottomBannerSlide, NewsSlides, ReviewSlides } from 'views/Homepage/previews';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material';
import { normalizeItem } from 'utils/common';

export const popupPreviewTheme = createTheme({
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: 'xl',
      },
      styleOverrides: {
        maxWidthXl: {
          maxWidth: 'calc(1280px + 48px) !important',
        },
        maxWidthMd: {
          maxWidth: 'calc(880px + 48px) !important',
        },
        maxWidthSm: {
          maxWidth: 'calc(640px + 48px) !important',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        color: 'primary',
        size: 'medium',
        disableElevation: true,
      },
      styleOverrides: {
        root: { textTransform: 'none' },
        sizeLarge: { minHeight: 56, minWidth: 56, fontSize: 20 },
        sizeMedium: { minHeight: 40, minWidth: 40 },
        sizeSmall: { minHeight: 32, minWidth: 32 },
        outlinedInherit: { borderColor: '#FFF3' },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
    },
  },
  typography: {
    fontFamily: 'Segoe UI Variable',
  },
  palette: {
    primary: {
      main: '#CFB675',
    },
    mode: 'dark',
  },
});

const PopupPreview = ({ data = {}, onSuccess, onClose }) => {
  return (
    <ThemeProvider theme={responsiveFontSizes(popupPreviewTheme)}>
      <DialogTitle className='text-white flex justify-between items-center z-10' style={{ backgroundColor: '#2b2b2b' }}>
        <Button variant='outlined' color='inherit' startIcon={<ArrowBack />} onClick={onClose}>
          Back
        </Button>
        <Typography variant='h5' component='div'>
          Preview
        </Typography>
        <Button onClick={onSuccess}>Publish</Button>
      </DialogTitle>
      <DialogContent className='text-white pb-10' style={{ backgroundColor: '#2b2b2b' }}>
        <Container>
          <GameSlides items={data.banners} />

          <div className='flex justify-between mb-10 mt-20'>
            <h2 className='font-bold text-4xl'>Top Items</h2>
            <a href='/'>
              <Button variant='outlined' color='inherit'>
                View more
              </Button>
            </a>
          </div>
          <CardSlides items={data.items.map((item) => normalizeItem(item))} />

          <div className='flex justify-between mb-10 mt-20'>
            <h2 className='font-bold text-4xl'>Popular games</h2>
            <a href='/'>
              <Button variant='outlined' color='inherit'>
                View more
              </Button>
            </a>
          </div>
          <Grid container spacing={5} className='mb-20'>
            {data.games.slice(0, 6).map((item) => (
              <Grid item key={item.id} xs={12} sm={6} md={4} lg={4}>
                <CardGame item={item} />
              </Grid>
            ))}
          </Grid>

          <div className='mt-20'>
            <BottomBannerSlide items={data.ads} />
          </div>

          <h2 className='font-bold text-4xl mb-10 mt-20'>News</h2>
          <NewsSlides items={data.news} />

          <h2 className='font-bold text-4xl mb-10 mt-20'>Reviews</h2>
          <ReviewSlides items={data.reviews} />
        </Container>
      </DialogContent>
    </ThemeProvider>
  );
};

export default PopupPreview;
