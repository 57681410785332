import React from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
import { Avatar, Button, Checkbox, Divider, FormControlLabel, Grid, Paper, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { KeyboardArrowDown } from '@mui/icons-material';
import { gameService } from 'services/game';
import { capitalCase } from 'change-case';

const GameAttribute = () => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [displayAttributes, setDisplayAttributes] = React.useState(Array(4).fill('-'));
  const [filterAttributes, setFilterAttributes] = React.useState([]);

  const { data: game } = useQuery(['gameService.getGameDetail', { id }], ({ queryKey }) =>
    gameService.getGameDetail({ ...queryKey[1] }).then((game) => {
      const { displayAttributes, filterAttributes, genericNft } = game;
      setDisplayAttributes((displayAttributes ?? []).filter((item) => Object.keys(genericNft).includes(item)));
      setFilterAttributes((filterAttributes ?? []).filter((item) => Object.keys(genericNft).includes(item)));
      return game;
    }),
  );

  const { mutate: updateGameDisplay, isLoading: isLoadingDisplay } = useMutation(gameService.updateGameDisplay, {
    onSuccess: () => {
      enqueueSnackbar('Update display attributes successfully', { variant: 'success' });
    },
  });

  const { mutate: updateGameFilter, isLoading: isLoadingFilter } = useMutation(gameService.updateGameFilter, {
    onSuccess: () => {
      enqueueSnackbar('Update filter attributes successfully', { variant: 'success' });
    },
  });

  const handleClickUpdateDisplay = () => {
    updateGameDisplay({ id, attributes: displayAttributes });
  };

  const handleClickUpdateFilter = () => {
    updateGameFilter({ id, attributes: filterAttributes });
  };

  return (
    <>
      <Grid container spacing={3} className='mb-5'>
        <Grid item sm>
          <Paper className='p-6 mb-5'>
            <Typography variant='h4' className='mb-1'>
              Config attributes are displayed at the item card
            </Typography>
            <div className='mb-3'>Choose maximum 4 attributes to show in card</div>

            <Grid container columnSpacing={3}>
              {Object.keys(game.genericNft ?? {}).map((item, index) => (
                <Grid item key={index} xl={3} sm={4}>
                  <FormControlLabel
                    label={capitalCase(item)}
                    control={<Checkbox className='p-1.5' checked={displayAttributes.includes(item)} />}
                    onChange={(event, checked) => {
                      if (checked) {
                        setDisplayAttributes((array) => array.concat(item).slice(0, 4));
                      } else {
                        setDisplayAttributes((array) => array.filter((next) => next !== item));
                      }
                    }}
                  />
                </Grid>
              ))}
            </Grid>

            <div className='flex justify-end mt-3'>
              <LoadingButton className='w-32' loading={isLoadingDisplay} onClick={handleClickUpdateDisplay}>
                Save Display
              </LoadingButton>
            </div>
          </Paper>
        </Grid>
        <Grid item>
          <Paper className='bg-black text-white w-80'>
            <div className='pt-3 px-6'>
              <div className='flex justify-center mb-4'>
                <img
                  src='https://cg.mypinata.cloud/ipfs/QmPqoKwo6TZKfaSmS286snABRYePoGDu2QjiBF27TWpDqo'
                  style={{ height: 280 }}
                />
              </div>
              <Typography variant='subtitle1'>Lorem Ipsum #666</Typography>
              <Typography variant='subtitle2' color='#FFF9' className='flex items-center gap-1'>
                <Avatar sx={{ width: 24, height: 24 }} src={game.logoMedia?.url} />
                <div>{game.title}</div>
              </Typography>
              <Divider className='border-gray-500 my-3' />
              {displayAttributes.concat(Array(4 - displayAttributes.length).fill('-')).map((item, index) => (
                <div key={index} className='flex justify-between mb-3'>
                  <div>{capitalCase(item)}</div>
                  <div>-</div>
                </div>
              ))}
            </div>
            <div className='bg-gray-500 px-6 py-3'>
              <Typography variant='h5'>0.1234 ETH</Typography>
            </div>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={3} className='mb-5'>
        <Grid item sm>
          <Paper className='p-6 mb-5'>
            <Typography variant='h4' className='mb-1'>
              Config attributes are displayed as filter
            </Typography>
            <div className='mb-3'>Choose attributes to show as filter</div>

            <Grid container columnSpacing={3}>
              {Object.keys(game.genericNft ?? {}).map((item, index) => (
                <Grid item key={index} xl={3} sm={4}>
                  <FormControlLabel
                    label={capitalCase(item)}
                    control={<Checkbox className='p-1.5' checked={filterAttributes.includes(item)} />}
                    onChange={(event, checked) => {
                      if (checked) {
                        setFilterAttributes((array) => array.concat(item));
                      } else {
                        setFilterAttributes((array) => array.filter((next) => next !== item));
                      }
                    }}
                  />
                </Grid>
              ))}
            </Grid>
            <div className='flex justify-end mt-3'>
              <LoadingButton className='w-32' loading={isLoadingFilter} onClick={handleClickUpdateFilter}>
                Save Filter
              </LoadingButton>
            </div>
          </Paper>
        </Grid>
        <Grid item>
          <Paper className='bg-black text-white w-80 p-6'>
            <div className='bg-gray-500 px-6 mb-3 py-3'>
              <Typography variant='h5'>Game Filter</Typography>
            </div>
            {filterAttributes.map((item, index) => (
              <Button key={index} fullWidth variant='text' color='inherit' className='justify-between mb-2'>
                <span>{capitalCase(item)}</span>
                <KeyboardArrowDown />
              </Button>
            ))}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default GameAttribute;
