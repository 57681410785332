import { Avatar, CardMedia, Paper } from '@mui/material';

const CardGame = ({ item = {} }) => {
  return (
    <Paper className='card-container p-2'>
      <CardMedia image={item.logoMedia?.url} component='img' className='rounded mb-2' style={{ height: 280 }} />
      <div className='flex items-center rounded-lg'>
        <Avatar src={item.developer?.logo} className='w-12 h-12 mr-3' />
        <div>
          <div className='text-lg font-bold max-line-1'>{item.title}</div>
          <div className='flex text-light-90 text-sm font-medium'>Developer: {item.developer?.name}</div>
        </div>
      </div>
    </Paper>
  );
};

export default CardGame;
