import React from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import { Collapse, FormControlLabel, Paper, Switch } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { gameService } from 'services/game';
import { FieldContract, FieldFee, FieldCurrency } from 'views/Games/components';

const GameConfig = ({ config = {}, networkId }) => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isActive, setIsActive] = React.useState(config.status === 'ACTIVE');

  const { primarySale = {}, secondarySale = {} } = config.feeStructures ?? {};

  const [others, setOthers] = React.useState({});
  const handleChangeOthers = React.useCallback((next) => {
    setOthers((current) => ({ ...current, ...next }));
  }, []);

  const { mutate: updateGameConfig, isLoading: isLoadingConfig } = useMutation(gameService.updateGameConfig, {
    onSuccess: () => {
      enqueueSnackbar('Update game config successfully', { variant: 'success' });
    },
  });

  const handleClickUpdate = () => {
    const { contracts, feeStructures, paymentTokenIds, royalReceiverAddress } = others;
    updateGameConfig({
      id,
      networkId: networkId,
      smartContracts: contracts.map(({ address }) => address),
      feeStructures,
      paymentTokenIds,
      royalReceiverAddress,
      status: isActive ? 'ACTIVE' : 'INACTIVE',
    });
  };

  return (
    <>
      <Paper className='p-6'>
        <FormControlLabel
          control={<Switch checked={isActive} onChange={(event, checked) => setIsActive(checked)} />}
          label='Turn ON to set up for this network'
        />
      </Paper>

      <Collapse in={isActive}>
        <Paper className='p-6 pt-0 mb-5'>
          <FieldContract initialValue={config.smartContracts} onChange={handleChangeOthers} />
        </Paper>

        <Paper className='p-6 mb-5'>
          <FieldFee
            initialValue={{
              primarySale: { commissionFee: primarySale.commissionFee ?? 5 },
              secondarySale: {
                commissionFee: secondarySale.commissionFee ?? 2.5,
                royaltyFee: secondarySale.royaltyFee ?? 0,
              },
              royalReceiverDefault: config.royalReceiverAddress,
            }}
            onChange={handleChangeOthers}
          />
        </Paper>

        <Paper className='p-6'>
          <FieldCurrency initialValue={config.paymentTokens} networkId={networkId} onChange={handleChangeOthers} />
        </Paper>
      </Collapse>

      <div className='flex justify-end gap-5 mt-5'>
        <LoadingButton className='w-40' loading={isLoadingConfig} onClick={handleClickUpdate}>
          Update
        </LoadingButton>
      </div>
    </>
  );
};

export default GameConfig;
