import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { usePopup } from 'hooks';
import { useSnackbar } from 'notistack';
import { EnhancedTable, Spinner } from 'components';
import { Avatar, Button, Dialog, Pagination, Paper, Typography, Switch, DialogActions } from '@mui/material';
import { SportsEsportsOutlined, WidgetsOutlined, Edit, Settings } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { gameService } from 'services/game';
import { DateTime } from 'luxon';
import { privateRoute } from 'routes';
import { PopupCreateDeveloper } from 'views/Developers/components';
import { PopupCreateGame } from './components';

const GameList = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [isOpenGame, onOpenGame, onCloseGame] = usePopup(false);
  const [isOpenDeveloper, onOpenDeveloper, onCloseDeveloper] = usePopup(false);
  const [isOpenConfirm, onOpenConfirm, onCloseConfirm] = usePopup(false);
  const [chosenConfirm, setChosenConfirm] = React.useState({});

  const [dataSearch, setDataSearch] = React.useState({ page: 1, limit: 10 });
  const { data, isFetching, refetch } = useQuery(
    ['gameService.fetchGames', dataSearch],
    ({ queryKey }) => gameService.fetchGames({ ...queryKey[1] }),
    { keepPreviousData: true },
  );
  const dataList = (data.items ?? []).map((item) => ({
    ...item,
    active: item.status === 'ACTIVE',
  }));
  const { totalPages = 0, currentPage = 1 } = data ?? {};

  const { mutate: updateGameStatus, isLoading: isLoadingConfirm } = useMutation(gameService.updateGameStatus, {
    onSuccess: () => {
      enqueueSnackbar('Update game successfully', { variant: 'success' });
      onCloseConfirm(false);
      refetch();
    },
  });

  const handleChangeSearch = React.useCallback((search) => {
    setDataSearch((current) => ({
      ...current,
      ...search,
    }));
  }, []);

  return (
    <Paper className='p-6'>
      <div className='flex justify-end gap-4 mb-6'>
        <Button startIcon={<SportsEsportsOutlined />} onClick={onOpenGame}>
          Create Game
        </Button>
        <Button startIcon={<WidgetsOutlined />} onClick={onOpenDeveloper}>
          Create Developer
        </Button>
      </div>

      <Spinner spinning={isFetching}>
        <EnhancedTable
          data={dataList}
          columns={[
            {
              name: 'title',
              label: 'GAME',
              options: {
                customBodyRender: (value, { rowIndex }) => (
                  <div className='flex items-end gap-2'>
                    <Avatar src={dataList[rowIndex].logoMedia?.url} />
                    <div>{value}</div>
                  </div>
                ),
              },
            },
            {
              name: 'developer',
              label: 'DEVELOPER',
              options: {
                customBodyRender: (developer) => (
                  <div className='flex items-end gap-2'>
                    <Avatar src={developer.logo} />
                    <div>{developer.name}</div>
                  </div>
                ),
              },
            },
            {
              name: 'active',
              label: 'ACTIVE',
              options: {
                customBodyRender: (active, { rowIndex }) => {
                  const item = dataList[rowIndex];
                  return (
                    <Switch
                      color='info'
                      checked={active}
                      onClick={() => {
                        onOpenConfirm();
                        setChosenConfirm(item);
                      }}
                    />
                  );
                },
              },
            },
            {
              name: 'createdAt',
              label: 'CREATED AT',
              options: {
                customBodyRender: (value) => DateTime.fromISO(value).toFormat('dd/MM/yyyy HH:mm'),
              },
            },
            {
              name: 'id',
              label: ' ',
              options: {
                customBodyRender: (id) => (
                  <div className='flex justify-end gap-3'>
                    <Link to={privateRoute.gameAttribute.url(id)}>
                      <Button variant='outlined' color='info' size='small' startIcon={<Settings />}>
                        Attributes
                      </Button>
                    </Link>
                    <Link to={privateRoute.gameView.url(id)}>
                      <Button variant='outlined' color='info' size='small' startIcon={<Edit />}>
                        Edit
                      </Button>
                    </Link>
                  </div>
                ),
              },
            },
          ]}
          options={{
            ...EnhancedTable.options,
          }}
        />
      </Spinner>
      <div className='flex justify-center mt-6'>
        <Pagination count={totalPages} page={currentPage} onChange={(event, page) => handleChangeSearch({ page })} />
      </div>

      <Dialog open={isOpenConfirm}>
        <div className='py-8 px-10'>
          <Typography variant='h4' className='mb-6'>
            {chosenConfirm.active ? 'Disable' : 'Active'} Game
          </Typography>
          <Typography className='mb-10'>
            Are you sure you want to {chosenConfirm.active ? 'disable' : 'active'}{' '}
            <Typography variant='h6' component='span'>
              {chosenConfirm.title}
            </Typography>
          </Typography>
          <DialogActions>
            <Button color='inherit' className='w-24' onClick={onCloseConfirm}>
              No
            </Button>
            <LoadingButton
              className='w-24'
              color={chosenConfirm.active ? 'error' : 'primary'}
              loading={isLoadingConfirm}
              onClick={() =>
                updateGameStatus({
                  id: chosenConfirm.id,
                  status: chosenConfirm.active ? 'INACTIVE' : 'ACTIVE',
                })
              }
            >
              Yes
            </LoadingButton>
          </DialogActions>
        </div>
      </Dialog>
      <Dialog open={isOpenGame}>
        <PopupCreateGame onSuccess={refetch} onClose={onCloseGame} />
      </Dialog>
      <Dialog open={isOpenDeveloper}>
        <PopupCreateDeveloper onClose={onCloseDeveloper} />
      </Dialog>
    </Paper>
  );
};

export default GameList;
