import axios from 'axios';
import { client } from './axios';

const uploadLink = (body) => client.post(`/upload-link/image-or-video`, body);
const uploadFile = (url, file) => axios.put(url, file, { headers: { 'Content-Type': file.type } });

export const fileService = {
  uploadLink,
  uploadFile,
};
