import React from 'react';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Avatar, FormControl, IconButton, TextField, Typography } from '@mui/material';
import { Close, ImageOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { gameService } from 'services/game';
import { fileService } from 'services/file';
import { getBase64 } from 'utils/common';

const PopupCreateDeveloper = ({ item = {}, onSuccess, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, clearErrors, setValue } = useForm();
  const [logo, setLogo] = React.useState();
  const isCreate = !item.id;

  const { mutate: createDeveloper, isLoading } = useMutation(gameService.createDeveloper, {
    onSuccess: () => {
      enqueueSnackbar('Create developer successfully', { variant: 'success' });
      onSuccess();
      onClose();
    },
  });

  const { mutate: updateDeveloper, isLoading: isLoadingUpdate } = useMutation(gameService.updateDeveloper, {
    onSuccess: () => {
      enqueueSnackbar('Update developer successfully', { variant: 'success' });
      onSuccess();
      onClose();
    },
  });

  const handleClickCreate = () => {
    handleSubmit((values) => {
      if (isCreate) {
        createDeveloper({ ...values });
      } else {
        updateDeveloper({ id: item.id, ...values });
      }
    })();
  };

  const handleChangeFile = (event) => {
    const [file] = event.target.files;
    if (!file) return;
    getBase64(file, setLogo);
    fileService.uploadLink({ fileNames: [file.name], type: 'DEVELOPER_LOG' }).then(([url]) => {
      fileService.uploadFile(url, file);
      const { origin, pathname } = new URL(url);
      setValue('logo', origin + pathname);
      clearErrors('logo');
    });
  };

  React.useEffect(() => {
    if (item.id) {
      setLogo(item.logo);
      setValue('logo', item.logo);
      setValue('name', item.name);
    }
  }, [item, setValue]);

  return (
    <div className='py-8 px-10' style={{ width: 480 }}>
      <Typography variant='h3' className='mb-6'>
        {isCreate ? 'Create' : 'Update'} Developer
      </Typography>
      <IconButton className='absolute top-6 right-6' onClick={onClose}>
        <Close />
      </IconButton>

      <Controller
        name='name'
        defaultValue=''
        control={control}
        rules={{ required: ' ' }}
        render={({ field, fieldState: { invalid, error } }) => (
          <FormControl fullWidth className='mb-6'>
            <label>Developer</label>
            <TextField {...field} error={invalid} />
          </FormControl>
        )}
      />
      <Controller
        name='logo'
        defaultValue=''
        control={control}
        rules={{ required: ' ' }}
        render={({ field, fieldState: { invalid, error } }) => (
          <>
            <input hidden type='file' id='logo' accept='image/*' onChange={handleChangeFile} />
            <div className='flex'>
              <label htmlFor='logo' className='rounded-full cursor-pointer mr-3'>
                <Avatar
                  src={logo}
                  className={'border-2 border-dashed hover:border-gray-500 h-20 w-20'}
                  style={invalid ? { borderColor: 'orangered' } : {}}
                >
                  <ImageOutlined className='w-14 h-14' />
                </Avatar>
              </label>
              <FormControl fullWidth>
                <label>Logo</label>
                <div>
                  Recommend an image of at least 800 x 800px.
                  <br />
                  Maximum size 5MB.
                </div>
              </FormControl>
            </div>
          </>
        )}
      />

      <div className='flex justify-end mt-6'>
        <LoadingButton className='w-48' loading={item ? isLoadingUpdate : isLoading} onClick={handleClickCreate}>
          {isCreate ? 'Create' : 'Update'} Developer
        </LoadingButton>
      </div>
    </div>
  );
};

export default PopupCreateDeveloper;
