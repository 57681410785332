import React from 'react';
import { usePopup } from 'hooks';
import { Button, CardMedia, Dialog, Typography } from '@mui/material';
import { Table, TableHead, TableBody, TableCell, TableRow } from '@mui/material';
import { Add, DehazeRounded, Delete, Edit } from '@mui/icons-material';
import { PopupBanner } from 'views/Homepage/popups';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { onDragEnd } from 'utils/validator';

const FieldBanner = ({ initialValues, onChange }) => {
  const [items, setItems] = React.useState([]);
  const isValid = items.length >= 4;

  const [isOpenPopup, onOpenPopup, onClosePopup] = usePopup();
  const [chosenIndex, setChosenIndex] = React.useState();

  const handleClickAdd = () => {
    onOpenPopup();
    setChosenIndex(items.length);
  };

  const handleClickReset = () => {
    setItems(initialValues?.slice() ?? []);
  };

  React.useEffect(() => {
    setItems(initialValues?.slice() ?? []);
  }, [initialValues]);

  React.useEffect(() => {
    onChange(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  return (
    <div>
      <div className='flex gap-6 mb-2'>
        <Typography variant='h3'>Top Banners</Typography>

        <div className='flex gap-3'>
          <Button size='small' variant='outlined' color='error' className='w-20' onClick={handleClickReset}>
            Reset
          </Button>
          <Button size='small' className='w-20' startIcon={<Add />} onClick={handleClickAdd}>
            Add
          </Button>
        </div>
      </div>
      <Typography className='mb-8' variant='subtitle1' color={isValid ? 'inherit' : 'error'}>
        Config at least 4 items to display
      </Typography>

      <Table className='mb-2'>
        <TableHead>
          <TableRow>
            <TableCell className='w-20' />
            <TableCell>Image</TableCell>
            <TableCell>Banner</TableCell>
            <TableCell>Status</TableCell>
            <TableCell className='text-right'>Actions</TableCell>
          </TableRow>
        </TableHead>
        <DragDropContext onDragEnd={onDragEnd(setItems)}>
          <Droppable droppableId='bannerList'>
            {(provided) => (
              <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                {items.map((item, index) => (
                  <Draggable draggableId={index.toString()} index={index} key={index}>
                    {(provided) => (
                      <TableRow ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <TableCell>
                          <div className='flex justify-center'>
                            <DehazeRounded sx={{ color: '#7A7F9A' }} />
                          </div>
                        </TableCell>
                        <TableCell className='w-40'>
                          <CardMedia
                            component={item?.type.includes('video') ? 'video' : 'div'}
                            image={item.image}
                            className='bg-contain border h-20 w-28'
                            {...{ controls: true, autoPlay: true, muted: true, loop: true }}
                          />
                        </TableCell>
                        <TableCell>{item.title}</TableCell>
                        <TableCell>{item.status}</TableCell>
                        <TableCell>
                          <div className='flex justify-end gap-3'>
                            <Button
                              variant='outlined'
                              size='small'
                              color='error'
                              startIcon={<Delete />}
                              onClick={() => {
                                setItems((items) => {
                                  items.splice(index, 1);
                                  return items.slice();
                                });
                              }}
                            >
                              Remove
                            </Button>
                            <Button
                              variant='outlined'
                              size='small'
                              color='info'
                              startIcon={<Edit />}
                              onClick={() => {
                                setChosenIndex(index);
                                onOpenPopup();
                              }}
                            >
                              Update
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>

      <Dialog fullWidth maxWidth='sm' open={isOpenPopup}>
        <PopupBanner
          item={items[chosenIndex]}
          onSuccess={(item) => {
            setItems((items) => {
              items[chosenIndex] = item;
              return items.slice();
            });
          }}
          onClose={onClosePopup}
        />
      </Dialog>
    </div>
  );
};

export default FieldBanner;
