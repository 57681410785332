export const isValidAddress = (string) => {
  return /^0x[0-9a-fA-F]{40}$/.test(string);
};

export const isValidTransID = (string) => {
  return /^0x[0-9a-fA-F]{64}$/.test(string);
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const onDragEnd = (updateArray) => (result) => {
  if (!result.destination) {
    return;
  }
  if (result.destination.index === result.source.index) {
    return;
  }
  updateArray((items) => reorder(items, result.source.index, result.destination.index));
};
