import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { IconButton, TextField, Typography } from '@mui/material';
import { DialogActions, DialogContent, DialogTitle, FormControl } from '@mui/material';
import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useMutation } from 'react-query';
import { gameService } from 'services/game';

const PopupBanner = ({ item, onSuccess, onClose }) => {
  const { control, handleSubmit, setValue } = useForm();
  const isCreate = !item;

  const { mutate, isLoading } = useMutation(gameService.getItemDetail, {
    onSuccess: (data, values) => {
      onSuccess({ ...values, ...data });
      onClose();
    },
  });

  const handleClickCreate = () => {
    handleSubmit((values) => {
      mutate(values);
    })();
  };

  React.useEffect(() => {
    if (item) {
      Object.entries(item).forEach(([key, value]) => {
        setValue(key, value ?? '');
      });
    }
  }, [setValue, item]);

  return (
    <>
      <DialogTitle>
        <Typography variant='h3' component='div'>
          {isCreate ? 'Create' : 'Update'} Item
        </Typography>
        <IconButton className='absolute top-3 right-6' onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Controller
          name='id'
          defaultValue=''
          control={control}
          render={({ field, fieldState: { invalid, error } }) => (
            <FormControl fullWidth className='mb-4'>
              <label>ID</label>
              <TextField {...field} error={invalid} helperText={error?.message} />
            </FormControl>
          )}
        />

        <Controller
          name='note'
          defaultValue=''
          control={control}
          render={({ field, fieldState: { invalid, error } }) => (
            <FormControl fullWidth className='mb-4'>
              <label>Note</label>
              <TextField {...field} error={invalid} helperText={error?.message} />
            </FormControl>
          )}
        />
      </DialogContent>

      <DialogActions className='justify-end px-6 py-4'>
        <LoadingButton onClick={handleClickCreate} loading={isLoading}>
          {isCreate ? 'Create' : 'Update'} Item
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupBanner;
