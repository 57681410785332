import React from 'react';
import { useSnackbar } from 'notistack';
import { useQuery, useMutation } from 'react-query';
import { usePopup } from 'hooks';
import { EnhancedTable, Spinner } from 'components';
import { Button, Dialog, Pagination, Paper, DialogActions, Avatar, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Add, Edit, Delete } from '@mui/icons-material';
import { gameService } from 'services/game';
import { DateTime } from 'luxon';
import { PopupCreateDeveloper } from './components';

const Developer = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isOpenCreate, onOpenCreate, onCloseCreate] = usePopup(false);
  const [isOpenConfirm, onOpenConfirm, onCloseConfirm] = usePopup(false);
  const [chosenConfirm, setChosenConfirm] = React.useState({});

  const [dataSearch, setDataSearch] = React.useState({ page: 1, limit: 10 });
  const { data, isFetching, refetch } = useQuery(
    ['gameService.fetchDevelopers', dataSearch],
    ({ queryKey }) => gameService.fetchDevelopers({ ...queryKey[1] }),
    { keepPreviousData: true },
  );
  const { items: dataList = [] } = data;
  const { totalPages = 0, currentPage = 1 } = data ?? {};

  const { mutate: removeDeveloper, isLoading: isLoadingConfirm } = useMutation(gameService.removeDeveloper, {
    onSuccess: () => {
      enqueueSnackbar('Remove developer successfully', { variant: 'success' });
      onCloseConfirm();
      refetch();
    },
  });

  const handleChangeSearch = React.useCallback((search) => {
    setDataSearch((current) => ({
      ...current,
      ...search,
    }));
  }, []);

  return (
    <Paper className='p-6'>
      <div className='flex justify-end mb-6'>
        <Button
          startIcon={<Add />}
          onClick={() => {
            setChosenConfirm({});
            onOpenCreate();
          }}
        >
          Create Developer
        </Button>
      </div>

      <Spinner spinning={isFetching}>
        <EnhancedTable
          data={dataList}
          columns={[
            {
              name: 'name',
              label: 'DEVELOPER',
              options: {
                customBodyRender: (value, { rowIndex }) => (
                  <div className='flex items-end gap-2'>
                    <Avatar src={dataList[rowIndex].logo} />
                    <div>{value}</div>
                  </div>
                ),
              },
            },
            {
              name: 'games',
              label: 'GAME COUNT',
              options: {
                customBodyRender: (games) => games?.length,
              },
            },
            {
              name: 'createdAt',
              label: 'CREATED AT',
              options: {
                customBodyRender: (value) => DateTime.fromISO(value).toFormat('dd/MM/yyyy HH:mm'),
              },
            },

            {
              name: 'id',
              label: ' ',
              options: {
                customBodyRender: (value, { rowIndex }) => (
                  <div className='flex justify-end gap-3'>
                    <Button
                      variant='outlined'
                      color='error'
                      size='small'
                      startIcon={<Delete />}
                      onClick={() => {
                        setChosenConfirm(dataList[rowIndex]);
                        onOpenConfirm();
                      }}
                    >
                      Delete
                    </Button>
                    <Button
                      variant='outlined'
                      color='info'
                      size='small'
                      startIcon={<Edit />}
                      onClick={() => {
                        setChosenConfirm(dataList[rowIndex]);
                        onOpenCreate();
                      }}
                    >
                      Edit
                    </Button>
                  </div>
                ),
              },
            },
          ]}
          options={{
            ...EnhancedTable.options,
          }}
        />
      </Spinner>
      <div className='flex justify-center mt-6'>
        <Pagination count={totalPages} page={currentPage} onChange={(event, page) => handleChangeSearch({ page })} />
      </div>

      <Dialog open={isOpenCreate}>
        <PopupCreateDeveloper item={chosenConfirm} onSuccess={refetch} onClose={onCloseCreate} />
      </Dialog>
      <Dialog open={isOpenConfirm}>
        <div className='py-8 px-10'>
          <Typography variant='h4' className='mb-6'>
            Remove Developer
          </Typography>
          <Typography className='mb-10'>
            Are you sure you want to remove{' '}
            <Typography variant='h6' component='span'>
              {chosenConfirm.name}
            </Typography>
          </Typography>
          <DialogActions>
            <Button color='inherit' className='w-24' onClick={onCloseConfirm}>
              No
            </Button>
            <LoadingButton
              className='w-24'
              color='error'
              loading={isLoadingConfirm}
              onClick={() => removeDeveloper(chosenConfirm.id)}
            >
              Yes
            </LoadingButton>
          </DialogActions>
        </div>
      </Dialog>
    </Paper>
  );
};

export default Developer;
