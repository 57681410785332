import { client } from './axios';

const fetchNetworks = (params) => client.get(`/network`, { params });
const fetchPaymentTokens = (params) => client.get(`/payment-tokens`, { params });

const searchSales = (params) => client.get(`/sales`, { params });
const getSalesLink = (params) => client.defaults.baseURL + client.getUri({ url: `/sales/export`, params });

const fetchHomepage = ({ id = '02473df4-449d-454c-8b31-92080ba45389' } = {}) => client.get(`/settings/${id}`);
const updateHomepage = ({ id = '02473df4-449d-454c-8b31-92080ba45389', ...body }) =>
  client.put(`/settings/${id}`, body);

export const marketService = {
  fetchPaymentTokens,
  fetchNetworks,

  searchSales,
  getSalesLink,

  fetchHomepage,
  updateHomepage,
};
