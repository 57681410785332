import { Homepage } from 'views/Homepage';
import { GameList, GameView, GameAttribute } from 'views/Games';
import { DeveloperList } from 'views/Developers';
import { SalesInvoice } from 'views/SalesInvoice';

const privateRoute = {
  home: {
    path: '/homepage',
    component: Homepage,
    name: 'Homepage',
  },
  game: {
    path: '/games',
    component: GameList,
    name: 'Games',
  },
  gameView: {
    path: '/games/:id',
    url: (id) => `/games/${id}`,
    component: GameView,
  },
  gameAttribute: {
    path: '/games/:id/attributes',
    url: (id) => `/games/${id}/attributes`,
    component: GameAttribute,
  },
  developer: {
    path: '/developers',
    component: DeveloperList,
    name: 'Developers',
  },
  saleSummary: {
    path: '/sales-summary',
    component: SalesInvoice,
    name: 'Sales Summary',
  },
};

export default privateRoute;
