import React from 'react';
import { Grid } from '@mui/material';
import { CardNews } from 'views/Homepage/previews';
import { InfiniteSlider } from 'components';

const NewsSlides = ({ items }) => {
  return (
    <InfiniteSlider autoplay className='-mx-3'>
      {items.map((_, index) => (
        <Grid key={index} container spacing={3} className='flex -ml-3'>
          {items
            .concat(items, items, items)
            .slice(index * 4, (index + 1) * 4)
            .filter((item, index, array) => array.findIndex((next) => next.index === item.index) === index)
            .map((item) => (
              <Grid item key={item.index} xs={12} sm={6} md={6} lg={6}>
                <a href={item.link ?? ''} target={item.isOpenNew ? '_blank' : '_self'}>
                  <CardNews item={item} />
                </a>
              </Grid>
            ))}
        </Grid>
      ))}
    </InfiniteSlider>
  );
};

export default NewsSlides;
