import React from 'react';
import { useQuery } from 'react-query';
import { Copiable, Dropdown, EnhancedTable, Spinner } from 'components';
import { ExpandButton } from 'mui-datatables';
import { LoadingButton } from '@mui/lab';
import { Pagination, Paper, TableCell, TableRow } from '@mui/material';
import { FileDownloadOutlined, OpenInNew } from '@mui/icons-material';
import { marketService } from 'services/market';
import { formatTime, shorten } from 'utils/common';
import SalesFilter from './SalesFilter';

const SaleType = ({ saleType }) => {
  const isPrimary = saleType === 'PRIMARY';
  return (
    <span className={'font-medium rounded py-0.5 px-2 text-white ' + (isPrimary ? 'bg-red-400' : 'bg-blue-400')}>
      {saleType}
    </span>
  );
};

const SalesInvoice = () => {
  const [dataSearch, setDataSearch] = React.useState({ limit: 10 });

  const { data = {}, isFetching } = useQuery(
    ['marketService.searchSales', dataSearch],
    ({ queryKey }) => marketService.searchSales({ ...queryKey[1] }),
    { keepPreviousData: true, enabled: !!dataSearch.page },
  );
  const { items: dataList = [], meta: { currentPage = 1, totalPages = 1 } = {} } = data;

  const handleChangeSearch = React.useCallback((search) => {
    setDataSearch((current) => ({
      ...current,
      page: 1,
      ...search,
    }));
  }, []);

  const handleDownload = (search) => {
    window.open(marketService.getSalesLink({ ...search, ...dataSearch }), '_blank');
  };

  return (
    <>
      <Paper className='p-6'>
        <SalesFilter onSearch={handleChangeSearch} />
      </Paper>

      <Paper className='mt-6 p-6'>
        <Spinner spinning={isFetching}>
          <EnhancedTable
            data={dataList}
            columns={[
              {
                name: 'createdAt',
                label: 'TIME',
                options: {
                  customBodyRender: (value) => formatTime(value),
                },
              },
              {
                name: 'paymentTxEvent',
                label: 'TXHASH',
                options: {
                  customBodyRender: (value, { rowIndex }) => {
                    const { nft, paymentTxEvent } = dataList[rowIndex];
                    return (
                      <div className='flex gap-1'>
                        {shorten(value.transactionHash)}
                        <a
                          href={`${nft.network.blockExplorerUrl}/tx/${paymentTxEvent.transactionHash}`}
                          target='_blank'
                          rel='noreferrer'
                          className='flex items-center text-sm align-bottom'
                        >
                          <OpenInNew sx={{ fontSize: 18 }} className='hover:text-neutral-300' />
                        </a>
                      </div>
                    );
                  },
                },
              },
              {
                name: 'nft',
                label: 'ITEM',
                options: { customBodyRender: (value) => value.name },
              },
              {
                name: 'nft',
                label: 'BLOCKCHAIN',
                options: { customBodyRender: (value) => value.network.name },
              },
              {
                name: 'saleType',
                label: 'SALE TYPE',
                options: { customBodyRender: (value) => <SaleType saleType={value} /> },
              },
              {
                name: 'seller',
                label: 'SELLER',
                options: { customBodyRender: (value) => <Copiable address={value} /> },
              },
              {
                name: 'paymentTxEvent',
                label: 'PURCHASER',
                options: { customBodyRender: (value) => <Copiable address={value.buyer} /> },
              },
              {
                name: 'paymentTxEvent',
                label: 'RECEIVER',
                options: {
                  customBodyRender: (value, { rowIndex }) => <Copiable address={value.seller} />,
                },
              },
              {
                name: 'price',
                label: 'PRICE',
                options: {
                  setCellHeaderProps: () => ({ className: 'text-right' }),
                  setCellProps: () => ({ className: 'text-right' }),
                },
              },
              {
                name: 'paymentToken',
                label: 'CURRENCY',
                options: {
                  customBodyRender: (value) => value.symbol,
                },
              },
            ]}
            options={{
              ...EnhancedTable.options,
              expandableRows: true,
              renderExpandableRow: (value, { rowIndex }) => {
                const { transactions = [] } = dataList[rowIndex] ?? {};
                return transactions.map((item, index) => (
                  <TableRow key={index} className='bg-blue-50'>
                    <TableCell style={{ borderLeft: '4px solid var(--neutral-light-100)' }} />
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <SaleType saleType={item.saleType} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <Copiable address={item.paymentTxEvent.seller} />
                    </TableCell>
                    <TableCell className='text-right'>{item.amount}</TableCell>
                    <TableCell>{item.paymentToken.symbol}</TableCell>
                  </TableRow>
                ));
              },
            }}
            components={{
              ExpandButton: ({ dataIndex, ...props }) => {
                const { transactions = [] } = dataList[dataIndex] ?? {};
                return transactions.length > 0 && <ExpandButton {...props} />;
              },
            }}
          />
        </Spinner>

        <div className='flex justify-between mt-6'>
          <div className='w-20' />
          <Pagination
            page={currentPage}
            count={Math.ceil(totalPages)}
            onChange={(event, page) => handleChangeSearch({ page })}
          />

          <Dropdown
            trigger={<LoadingButton startIcon={<FileDownloadOutlined />}>Export</LoadingButton>}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Dropdown.Option onClick={() => handleDownload({ format: 'xlsx' })}>EXCEL</Dropdown.Option>
            <Dropdown.Option onClick={() => handleDownload({ format: 'csv' })}>CSV</Dropdown.Option>
          </Dropdown>
        </div>
      </Paper>
    </>
  );
};

export default SalesInvoice;
