import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { TextField, Typography } from '@mui/material';
import { Table, TableHead, TableBody, TableCell, TableRow } from '@mui/material';
import { InputNumberFormat } from 'components';
import { isValidAddress } from 'utils/validator';

const FieldFee = ({ initialValue = {}, onChange }) => {
  const { primarySale = {}, secondarySale = {}, royalReceiverDefault } = initialValue;

  const { control, watch, reset } = useForm({ mode: 'onChange' });
  const { primaryCommission, secondaryCommission, secondaryRoyalty, royalReceiverAddress } = watch();

  React.useEffect(() => {
    reset({
      primaryCommission: primarySale.commissionFee,
      secondaryCommission: secondarySale.commissionFee,
      secondaryRoyalty: secondarySale.royaltyFee,
      royalReceiverAddress: royalReceiverDefault ?? '',
    });
  }, [reset, primarySale.commissionFee, secondarySale.commissionFee, secondarySale.royaltyFee, royalReceiverDefault]);

  React.useEffect(() => {
    onChange({
      feeStructures: {
        primarySale: { commissionFee: primaryCommission },
        secondarySale: { commissionFee: secondaryCommission, royaltyFee: secondaryRoyalty },
      },
      royalReceiverAddress,
    });
  }, [onChange, primaryCommission, secondaryCommission, secondaryRoyalty, royalReceiverAddress]);

  return (
    <div>
      <Typography variant='h5' className='mb-3'>
        Fee Structure
      </Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Commission Fee (%)</TableCell>
            <TableCell>Royalty Fee (%)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Primary Sale</TableCell>
            <TableCell>
              <Controller
                name='primaryCommission'
                defaultValue=''
                control={control}
                rules={{ required: ' ' }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField {...field} InputProps={{ inputComponent: InputNumberFormat }} error={invalid} />
                )}
              />
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Secondary Sale</TableCell>
            <TableCell>
              <Controller
                name='secondaryCommission'
                defaultValue=''
                control={control}
                rules={{ required: ' ' }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField {...field} InputProps={{ inputComponent: InputNumberFormat }} error={invalid} />
                )}
              />
            </TableCell>
            <TableCell>
              <Controller
                name='secondaryRoyalty'
                defaultValue=''
                control={control}
                rules={{ required: ' ' }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField {...field} InputProps={{ inputComponent: InputNumberFormat }} error={invalid} />
                )}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Typography variant='h5' className='mt-6 mb-1'>
        Wallet address to receive royalty fee
      </Typography>
      <Controller
        name='royalReceiverAddress'
        defaultValue=''
        control={control}
        render={({ field }) => (
          <>
            <TextField fullWidth {...field} />
            {!isValidAddress(royalReceiverAddress) && (
              <div className='text-red-600 mt-3'>
                {royalReceiverAddress > '' ? 'Address is not valid' : 'Please enter royalty address receiver'}
              </div>
            )}
          </>
        )}
      />
    </div>
  );
};

export default FieldFee;
