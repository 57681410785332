import { Avatar, CardMedia, Paper, Tooltip } from '@mui/material';
import { NetworkIcon } from 'utils/assets';
import { capitalCase } from 'change-case';

const CardItem = ({ item = {}, display = 'grid' }) => {
  return (
    <Paper className='card-container rounded-b-lg'>
      <div className='px-4 py-2'>
        <CardMedia
          component={item.isVideo ? 'video' : 'div'}
          image={item.image}
          className='bg-contain'
          style={{ height: 280 }}
        />
        <div className='flex justify-between text-lg font-medium my-2 max-line-1'>
          {item.name}
          <Tooltip title={item.network?.name ?? ''} placement='top'>
            <Avatar src={NetworkIcon[item.network?.name]} className='w-6 h-6' />
          </Tooltip>
        </div>
        <div className='flex text-light-90 text-sm pb-2'>{item.game.title}</div>
        {display === 'grid' && (
          <>
            <div className='w-full mt-1 mb-3' style={{ height: 1, backgroundColor: '#545454' }} />
            {item.game.displayAttributes?.map((key) => (
              <div key={key} className='flex justify-between text-sm mb-3'>
                <span>{capitalCase(key)}</span>
                <span>{item.attribute[key]}</span>
              </div>
            ))}
          </>
        )}
      </div>
      <div
        className='text-lg font-bold rounded-b-lg'
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
          margin: -2,
          padding: '16px 18px',
        }}
      >
        {item.isSale ? (
          <div>
            {parseFloat(item.sale?.price.toFixed(6))} {item.sale.paymentToken?.symbol}
          </div>
        ) : item.isOwner ? (
          <a href='/'>
            <div className='font-medium text-light-90 text-center'>
              <span>PUT ON MARKET</span>
            </div>
          </a>
        ) : (
          <div className='font-medium text-light-90 text-center'>
            <span>NOT FOR SALE</span>
          </div>
        )}
      </div>
    </Paper>
  );
};

export default CardItem;
