import axios from 'axios';
import { store } from 'reducers';
import { enqueueSnackbar } from 'reducers/coreui';
import { signOut } from 'reducers/profile';
import { stringify } from 'query-string';
import { API_URL } from 'env';

const onError = ({ response }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  if (response) {
    const { status, statusText, data } = response;
    if (status === 401) {
      store.dispatch(signOut());
    } else {
      const [message] = Array.isArray(data.message) ? data.message : [`${status} - ${statusText}`];
      store.dispatch(
        enqueueSnackbar({
          message: message.charAt(0).toUpperCase() + message.slice(1),
          variant: 'error',
          key: 'axios',
        }),
      );
    }
  } else {
    store.dispatch(enqueueSnackbar({ message: `Cannot connect to Server`, variant: 'error', key: 'axios' }));
  }
  return Promise.reject(response);
};

const beforeRequest = (config) => {
  const { isLoggedIn, token } = store.getState().profile;
  if (isLoggedIn) {
    Object.assign(config.headers, { Authorization: `Bearer ${token}` });
  }
  if (config.data instanceof FormData) {
    Object.assign(config.headers, { 'Content-Type': 'multipart/form-data' });
  }
  return config;
};

const client = axios.create({
  baseURL: API_URL,
  paramsSerializer: (params) => stringify(params, { arrayFormat: 'index' }),
});
client.interceptors.request.use(beforeRequest);

[client].forEach((client) => {
  client.interceptors.response.use(({ data: response }) => {
    return response;
  }, onError);
});

const clientRaw = axios.create({
  baseURL: API_URL,
  paramsSerializer: (params) => stringify(params, { arrayFormat: 'index' }),
});
clientRaw.interceptors.request.use(beforeRequest);

export { client, clientRaw };
