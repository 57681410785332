import React from 'react';
import { Button, CardMedia, Grid } from '@mui/material';
import { InfiniteSlider } from 'components';

const GameSlides = ({ items }) => {
  const [mainSlide, setMainSlide] = React.useState();
  const [sideSlide, setSideSlide] = React.useState();

  return (
    <>
      {items?.length > 0 && (
        <Grid container spacing={3}>
          <Grid item lg={9}>
            <InfiniteSlider asNavFor={sideSlide} ref={(slider) => setMainSlide(slider)} arrows={false}>
              {items.map((item, index) => (
                <div key={index} className='relative bg-black'>
                  <CardMedia
                    image={item.image}
                    sx={{ height: 652 }}
                    component={item?.type.includes('video') ? 'video' : 'div'}
                    {...{ controls: true, autoPlay: true, muted: true, loop: true }}
                  />
                  <div className='flex flex-col items-start justify-end p-8 absolute inset-0'>
                    <div className='font-bold text-sm mb-2'>{item.status}</div>
                    <div className='mb-8 max-w-sm break-words max-line-4' style={{ height: 88 }}>
                      {item.description}
                    </div>
                    <a href={item.link} target={item.isOpenNew ? '_blank' : '_self'}>
                      <Button color='primary' style={{ minWidth: 128 }}>
                        {item.button}
                      </Button>
                    </a>
                  </div>
                </div>
              ))}
            </InfiniteSlider>
          </Grid>
          <Grid item lg={3} style={{ height: 648 + 28, overflow: 'hidden' }}>
            <InfiniteSlider
              asNavFor={mainSlide}
              slidesToShow={Math.min(5, items.length)}
              ref={(slider) => setSideSlide(slider)}
              {...{ swipeToSlide: true, focusOnSelect: true, vertical: true, verticalSwiping: true, arrows: false }}
            >
              {items.map((item, index) => (
                <div key={index} className='flex items-center rounded-lg p-3 gap-4 bg-dark-50 hover:bg-dark-90 mb-3'>
                  <CardMedia image={item?.type.includes('video') ? item.thumbnail : item.image} className='h-24 w-32' />
                  <div className='font-bold'>{item.title}</div>
                </div>
              ))}
            </InfiniteSlider>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default GameSlides;
