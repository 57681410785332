import React from 'react';
import { Avatar, FormControl } from '@mui/material';
import { ImageOutlined } from '@mui/icons-material';
import { fileService } from 'services/file';

const FieldAvatarMedia = ({ initialValue, onChange }) => {
  const [avatarMedia, setAvatarMedia] = React.useState();

  const handleChangeFile = (event) => {
    const [file] = event.target.files;
    if (!file) return;
    fileService.uploadLink({ fileNames: [file.name], type: 'GAME_IMAGE' }).then(([url]) => {
      fileService.uploadFile(url, file).then(() => {
        const { origin, pathname } = new URL(url);
        setAvatarMedia(origin + pathname);
      });
    });
  };

  React.useEffect(() => {
    onChange({ avatarMedia: { url: avatarMedia } });
  }, [onChange, avatarMedia]);

  React.useEffect(() => {
    setAvatarMedia(initialValue?.url);
  }, [initialValue]);

  return (
    <div className='flex gap-5'>
      <input hidden type='file' id='avatarMedia' accept='image/*' onChange={handleChangeFile} />
      <div className='flex mb-3'>
        <label htmlFor='avatarMedia' className='rounded-full cursor-pointer'>
          <Avatar
            variant='rounded'
            src={avatarMedia}
            className={'border-2 border-dashed hover:border-gray-500 h-40 w-40'}
            style={avatarMedia ? {} : { borderColor: 'orangered' }}
          >
            <ImageOutlined className='w-20 h-20' />
          </Avatar>
        </label>
      </div>
      <FormControl fullWidth>
        <label>Avatar Media</label>
        <div>
          Recommend an image of at least 800 x 800px.
          <br />
          Maximum size 5MB.
        </div>
      </FormControl>
    </div>
  );
};

export default FieldAvatarMedia;
