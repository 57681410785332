import { DateTime } from 'luxon';

const YYYYMMDD = 'yyyy-MM-dd';
const DDMMYYYYHHMM = 'dd/MM/yyyy HH:mm';

export const shorten = (address, before = 8, after = 6) => {
  if (address.length < 10) return address;
  return address.slice(0, before) + '...' + address.slice(address.length - after);
};

export const getBase64 = (file, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(file);
};

export const formatDay = (millis = Date.now(), format = YYYYMMDD) => {
  return DateTime.fromMillis(millis).toFormat(format);
};

export const formatTime = (date, format = DDMMYYYYHHMM) => {
  return DateTime.fromISO(date).toFormat(format);
};

export const getTimeAgo = (time) => {
  const units = ['year', 'month', 'week', 'day', 'hour', 'minute', 'second'];
  const diff = DateTime.fromISO(time)
    .diffNow()
    .shiftTo(...units);
  const unit = units.find((unit) => diff.get(unit) !== 0) ?? units.reverse()[0];
  const relativeFormatter = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });
  return relativeFormatter.format(Math.trunc(diff.as(unit)), unit);
};

export const normalizeItem = (item = {}) => {
  const isSale = item.sale?.id > '';
  const isVideo = item.imageType?.includes('video');

  return {
    ...item,
    isSale,
    isVideo,
    sale: item.sale ?? {},
  };
};
