import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormControl, Grid, MenuItem, TextField } from '@mui/material';
import { DatePicker, LoadingButton } from '@mui/lab';
import { DateTime } from 'luxon';
import { useQuery } from 'react-query';
import { gameService } from 'services/game';
import { marketService } from 'services/market';

const TIME_RANGE_FILTERS = [
  { value: { days: 1 }, label: 'Last 24 hours', code: 'LAST_24_HOURS' },
  { value: { days: 7 }, label: 'Last 7 days', code: 'LAST_7_DAYS' },
  { value: { days: 30 }, label: 'Last 30 days', code: 'LAST_30_DAYS' },
  { value: { years: 1 }, label: 'Last year', code: 'LAST_YEAR' },
  { value: 0, label: 'Custom', code: 'CUSTOM_RANGE' },
];

const SALES_TYPE_FILTERS = [
  { value: ' ', label: 'All' },
  { value: 'PRIMARY', label: 'Primary Sale' },
  { value: 'SECONDARY', label: 'Secondary Sale' },
];

const SalesFilter = ({ onSearch }) => {
  const { data: { items: networks = [] } = {} } = useQuery(['marketService.fetchNetworks'], () =>
    marketService.fetchNetworks(),
  );

  const { data: { items: payments = [] } = {} } = useQuery(['marketService.fetchPaymentTokens'], () =>
    marketService.fetchPaymentTokens(),
  );

  const { data: { items: developers = [] } = {} } = useQuery(['gameService.fetchDevelopers'], () =>
    gameService.fetchDevelopers(),
  );

  const { data: { items: games = [] } = {} } = useQuery(['gameService.fetchGames'], () => gameService.fetchGames());

  const { control, handleSubmit, watch, setValue, clearErrors } = useForm({ mode: 'onChange' });
  const { networkId } = watch();

  React.useEffect(() => {
    const subscription = watch(({ timeRangeType }, { name, type }) => {
      if (type) {
        if (name === 'timeRangeType') {
          if (timeRangeType) {
            setValue(
              'startDate',
              DateTime.now().minus(TIME_RANGE_FILTERS.find((item) => item.code === timeRangeType).value),
            );
            setValue('endDate', DateTime.now());
          }
        }
        if (name === 'startDate' || name === 'endDate') {
          setValue('timeRangeType', TIME_RANGE_FILTERS[4].code);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue, clearErrors]);

  const checkNoValue = (value) => (value?.toString().trim().length === 0 ? undefined : value);

  const handleClickSearch = React.useCallback(() => {
    handleSubmit((values) => {
      const { timeRangeType, startDate, endDate, developerId, gameId, networkId, saleType, paymentTokenId } = values;
      onSearch({
        timeRangeType: timeRangeType,
        startDate: timeRangeType === 'CUSTOM_RANGE' ? Math.floor(startDate?.valueOf()) : undefined,
        endDate: timeRangeType === 'CUSTOM_RANGE' ? Math.floor(endDate?.valueOf()) : undefined,
        developerId: checkNoValue(developerId),
        gameId: checkNoValue(gameId),
        networkId: checkNoValue(networkId),
        saleType: checkNoValue(saleType),
        paymentTokenId: checkNoValue(paymentTokenId),
      });
    })();
  }, [handleSubmit, onSearch]);

  React.useEffect(() => {
    handleClickSearch();
  }, [handleClickSearch]);

  return (
    <>
      <Grid container columns={30} spacing={3} mb={3}>
        <Grid item xl={6} md={10}>
          <Controller
            name='timeRangeType'
            defaultValue={TIME_RANGE_FILTERS[2].code}
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <label>Time range</label>
                <TextField select {...field}>
                  {TIME_RANGE_FILTERS.map((item, index) => (
                    <MenuItem key={index} value={item.code}>
                      {item.label}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xl={6} md={10}>
          <Controller
            name='startDate'
            defaultValue={DateTime.now().minus(TIME_RANGE_FILTERS[2].value)}
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <label>Start date</label>
                <DatePicker
                  {...field}
                  maxDate={DateTime.now()}
                  inputFormat='dd/MM/yyyy'
                  renderInput={(params) => <TextField {...params} />}
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xl={6} md={10}>
          <Controller
            name='endDate'
            defaultValue={DateTime.now()}
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <label>End date</label>
                <DatePicker
                  {...field}
                  maxDate={DateTime.now()}
                  inputFormat='dd/MM/yyyy'
                  renderInput={(params) => <TextField {...params} />}
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xl={6} md={10}>
          <Controller
            name='developerId'
            defaultValue=' '
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <label>Game Developer</label>
                <TextField select {...field}>
                  <MenuItem value=' '>All</MenuItem>
                  {developers.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xl={6} md={10}>
          <Controller
            name='gameId'
            defaultValue=' '
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <label>Game</label>
                <TextField select {...field}>
                  <MenuItem value=' '>All</MenuItem>
                  {games.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.title}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
      <Grid container columns={30} spacing={3} mb={3}>
        <Grid item xl={6} md={10}>
          <Controller
            name='networkId'
            defaultValue=' '
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <label>Network</label>
                <TextField select {...field}>
                  <MenuItem value=' '>All</MenuItem>
                  {networks.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xl={6} md={10}>
          <Controller
            name='paymentTokenId'
            defaultValue=' '
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <label>Currency</label>
                <TextField select {...field}>
                  <MenuItem value=' '>All</MenuItem>
                  {payments
                    .filter((item) => item.networkId === networkId || !networkId?.trim())
                    .map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </TextField>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xl={6} md={10}>
          <Controller
            name='saleType'
            defaultValue={SALES_TYPE_FILTERS[0].value}
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <label>Sale Type</label>
                <TextField select {...field}>
                  {SALES_TYPE_FILTERS.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            )}
          />
        </Grid>
      </Grid>

      <LoadingButton onClick={handleClickSearch}>View Report</LoadingButton>
    </>
  );
};

export default SalesFilter;
